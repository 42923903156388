/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Bytes: { input: any; output: any; }
  /**
   * 8 bytes signed integer
   *
   */
  Int8: { input: any; output: any; }
};

export type BlockChangedFilter = {
  number_gte: Scalars['Int']['input'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelLockupProposal = {
  __typename?: 'CancelLockupProposal';
  administratorApproved: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['BigInt']['output'];
  keyKeeperApproved: Scalars['Boolean']['output'];
  owner: Scalars['Bytes']['output'];
  status: Scalars['Int']['output'];
  tokenOwnerApproved: Scalars['Boolean']['output'];
};

export type CancelLockupProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  administratorApproved?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  administratorApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  and?: InputMaybe<Array<InputMaybe<CancelLockupProposal_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  index?: InputMaybe<Scalars['BigInt']['input']>;
  index_gt?: InputMaybe<Scalars['BigInt']['input']>;
  index_gte?: InputMaybe<Scalars['BigInt']['input']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index_lt?: InputMaybe<Scalars['BigInt']['input']>;
  index_lte?: InputMaybe<Scalars['BigInt']['input']>;
  index_not?: InputMaybe<Scalars['BigInt']['input']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  keyKeeperApproved?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeperApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  or?: InputMaybe<Array<InputMaybe<CancelLockupProposal_Filter>>>;
  owner?: InputMaybe<Scalars['Bytes']['input']>;
  owner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  owner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  tokenOwnerApproved?: InputMaybe<Scalars['Boolean']['input']>;
  tokenOwnerApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  tokenOwnerApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  tokenOwnerApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export enum CancelLockupProposal_OrderBy {
  AdministratorApproved = 'administratorApproved',
  Id = 'id',
  Index = 'index',
  KeyKeeperApproved = 'keyKeeperApproved',
  Owner = 'owner',
  Status = 'status',
  TokenOwnerApproved = 'tokenOwnerApproved'
}

export type ExtendLockupPeriodProposal = {
  __typename?: 'ExtendLockupPeriodProposal';
  administratorApproved: Scalars['Boolean']['output'];
  extensionPeriod: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['BigInt']['output'];
  keyKeeperApproved: Scalars['Boolean']['output'];
  owner: Scalars['Bytes']['output'];
  status: Scalars['Int']['output'];
};

export type ExtendLockupPeriodProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  administratorApproved?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  administratorApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  and?: InputMaybe<Array<InputMaybe<ExtendLockupPeriodProposal_Filter>>>;
  extensionPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  extensionPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  extensionPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  extensionPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  extensionPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  extensionPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  extensionPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  extensionPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  index?: InputMaybe<Scalars['BigInt']['input']>;
  index_gt?: InputMaybe<Scalars['BigInt']['input']>;
  index_gte?: InputMaybe<Scalars['BigInt']['input']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index_lt?: InputMaybe<Scalars['BigInt']['input']>;
  index_lte?: InputMaybe<Scalars['BigInt']['input']>;
  index_not?: InputMaybe<Scalars['BigInt']['input']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  keyKeeperApproved?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeperApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  or?: InputMaybe<Array<InputMaybe<ExtendLockupPeriodProposal_Filter>>>;
  owner?: InputMaybe<Scalars['Bytes']['input']>;
  owner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  owner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum ExtendLockupPeriodProposal_OrderBy {
  AdministratorApproved = 'administratorApproved',
  ExtensionPeriod = 'extensionPeriod',
  Id = 'id',
  Index = 'index',
  KeyKeeperApproved = 'keyKeeperApproved',
  Owner = 'owner',
  Status = 'status'
}

export type GrantKeyKeeperProposal = {
  __typename?: 'GrantKeyKeeperProposal';
  administratorApproved: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  keyKeeperApproved: Scalars['Boolean']['output'];
  newKeyKeeper: Scalars['Bytes']['output'];
  status: Scalars['Int']['output'];
};

export type GrantKeyKeeperProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  administratorApproved?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  administratorApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  and?: InputMaybe<Array<InputMaybe<GrantKeyKeeperProposal_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  keyKeeperApproved?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeperApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  newKeyKeeper?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newKeyKeeper_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_not?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newKeyKeeper_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  or?: InputMaybe<Array<InputMaybe<GrantKeyKeeperProposal_Filter>>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum GrantKeyKeeperProposal_OrderBy {
  AdministratorApproved = 'administratorApproved',
  Id = 'id',
  KeyKeeperApproved = 'keyKeeperApproved',
  NewKeyKeeper = 'newKeyKeeper',
  Status = 'status'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Ownership = {
  __typename?: 'Ownership';
  amount: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['BigInt']['output'];
  lockupDeadline: Scalars['BigInt']['output'];
  owner: Scalars['Bytes']['output'];
  status: Scalars['Int']['output'];
};

export type Ownership_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<Ownership_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  index?: InputMaybe<Scalars['BigInt']['input']>;
  index_gt?: InputMaybe<Scalars['BigInt']['input']>;
  index_gte?: InputMaybe<Scalars['BigInt']['input']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index_lt?: InputMaybe<Scalars['BigInt']['input']>;
  index_lte?: InputMaybe<Scalars['BigInt']['input']>;
  index_not?: InputMaybe<Scalars['BigInt']['input']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lockupDeadline?: InputMaybe<Scalars['BigInt']['input']>;
  lockupDeadline_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lockupDeadline_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lockupDeadline_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lockupDeadline_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lockupDeadline_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lockupDeadline_not?: InputMaybe<Scalars['BigInt']['input']>;
  lockupDeadline_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Ownership_Filter>>>;
  owner?: InputMaybe<Scalars['Bytes']['input']>;
  owner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  owner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum Ownership_OrderBy {
  Amount = 'amount',
  Id = 'id',
  Index = 'index',
  LockupDeadline = 'lockupDeadline',
  Owner = 'owner',
  Status = 'status'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  cancelLockupProposal?: Maybe<CancelLockupProposal>;
  cancelLockupProposals: Array<CancelLockupProposal>;
  extendLockupPeriodProposal?: Maybe<ExtendLockupPeriodProposal>;
  extendLockupPeriodProposals: Array<ExtendLockupPeriodProposal>;
  grantKeyKeeperProposal?: Maybe<GrantKeyKeeperProposal>;
  grantKeyKeeperProposals: Array<GrantKeyKeeperProposal>;
  ownership?: Maybe<Ownership>;
  ownerships: Array<Ownership>;
  revokeKeyKeeperProposal?: Maybe<RevokeKeyKeeperProposal>;
  revokeKeyKeeperProposals: Array<RevokeKeyKeeperProposal>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  transferOwnershipProposal?: Maybe<TransferOwnershipProposal>;
  transferOwnershipProposals: Array<TransferOwnershipProposal>;
  transferTokenOwnershipProposal?: Maybe<TransferTokenOwnershipProposal>;
  transferTokenOwnershipProposals: Array<TransferTokenOwnershipProposal>;
  upgradeableProposal?: Maybe<UpgradeableProposal>;
  upgradeableProposals: Array<UpgradeableProposal>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryCancelLockupProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCancelLockupProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CancelLockupProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CancelLockupProposal_Filter>;
};


export type QueryExtendLockupPeriodProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryExtendLockupPeriodProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExtendLockupPeriodProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ExtendLockupPeriodProposal_Filter>;
};


export type QueryGrantKeyKeeperProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGrantKeyKeeperProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GrantKeyKeeperProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GrantKeyKeeperProposal_Filter>;
};


export type QueryOwnershipArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOwnershipsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Ownership_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Ownership_Filter>;
};


export type QueryRevokeKeyKeeperProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRevokeKeyKeeperProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RevokeKeyKeeperProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RevokeKeyKeeperProposal_Filter>;
};


export type QueryRoleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRolesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Role_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Role_Filter>;
};


export type QueryTransferOwnershipProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransferOwnershipProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferOwnershipProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransferOwnershipProposal_Filter>;
};


export type QueryTransferTokenOwnershipProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransferTokenOwnershipProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferTokenOwnershipProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransferTokenOwnershipProposal_Filter>;
};


export type QueryUpgradeableProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUpgradeableProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UpgradeableProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UpgradeableProposal_Filter>;
};

export type RevokeKeyKeeperProposal = {
  __typename?: 'RevokeKeyKeeperProposal';
  administratorApproved: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  keyKeeper: Scalars['Bytes']['output'];
  keyKeeperApproved: Scalars['Boolean']['output'];
  status: Scalars['Int']['output'];
};

export type RevokeKeyKeeperProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  administratorApproved?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  administratorApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  and?: InputMaybe<Array<InputMaybe<RevokeKeyKeeperProposal_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  keyKeeper?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeperApproved?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeperApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeper_contains?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_gt?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_gte?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  keyKeeper_lt?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_lte?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_not?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  keyKeeper_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  or?: InputMaybe<Array<InputMaybe<RevokeKeyKeeperProposal_Filter>>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum RevokeKeyKeeperProposal_OrderBy {
  AdministratorApproved = 'administratorApproved',
  Id = 'id',
  KeyKeeper = 'keyKeeper',
  KeyKeeperApproved = 'keyKeeperApproved',
  Status = 'status'
}

export type Role = {
  __typename?: 'Role';
  account: Scalars['Bytes']['output'];
  id: Scalars['ID']['output'];
  role: Scalars['Bytes']['output'];
};

export type Role_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']['input']>;
  account_contains?: InputMaybe<Scalars['Bytes']['input']>;
  account_gt?: InputMaybe<Scalars['Bytes']['input']>;
  account_gte?: InputMaybe<Scalars['Bytes']['input']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  account_lt?: InputMaybe<Scalars['Bytes']['input']>;
  account_lte?: InputMaybe<Scalars['Bytes']['input']>;
  account_not?: InputMaybe<Scalars['Bytes']['input']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  and?: InputMaybe<Array<InputMaybe<Role_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Role_Filter>>>;
  role?: InputMaybe<Scalars['Bytes']['input']>;
  role_contains?: InputMaybe<Scalars['Bytes']['input']>;
  role_gt?: InputMaybe<Scalars['Bytes']['input']>;
  role_gte?: InputMaybe<Scalars['Bytes']['input']>;
  role_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_lt?: InputMaybe<Scalars['Bytes']['input']>;
  role_lte?: InputMaybe<Scalars['Bytes']['input']>;
  role_not?: InputMaybe<Scalars['Bytes']['input']>;
  role_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  role_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
};

export enum Role_OrderBy {
  Account = 'account',
  Id = 'id',
  Role = 'role'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  cancelLockupProposal?: Maybe<CancelLockupProposal>;
  cancelLockupProposals: Array<CancelLockupProposal>;
  extendLockupPeriodProposal?: Maybe<ExtendLockupPeriodProposal>;
  extendLockupPeriodProposals: Array<ExtendLockupPeriodProposal>;
  grantKeyKeeperProposal?: Maybe<GrantKeyKeeperProposal>;
  grantKeyKeeperProposals: Array<GrantKeyKeeperProposal>;
  ownership?: Maybe<Ownership>;
  ownerships: Array<Ownership>;
  revokeKeyKeeperProposal?: Maybe<RevokeKeyKeeperProposal>;
  revokeKeyKeeperProposals: Array<RevokeKeyKeeperProposal>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  transferOwnershipProposal?: Maybe<TransferOwnershipProposal>;
  transferOwnershipProposals: Array<TransferOwnershipProposal>;
  transferTokenOwnershipProposal?: Maybe<TransferTokenOwnershipProposal>;
  transferTokenOwnershipProposals: Array<TransferTokenOwnershipProposal>;
  upgradeableProposal?: Maybe<UpgradeableProposal>;
  upgradeableProposals: Array<UpgradeableProposal>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionCancelLockupProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCancelLockupProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CancelLockupProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<CancelLockupProposal_Filter>;
};


export type SubscriptionExtendLockupPeriodProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionExtendLockupPeriodProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExtendLockupPeriodProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ExtendLockupPeriodProposal_Filter>;
};


export type SubscriptionGrantKeyKeeperProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGrantKeyKeeperProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GrantKeyKeeperProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GrantKeyKeeperProposal_Filter>;
};


export type SubscriptionOwnershipArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOwnershipsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Ownership_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Ownership_Filter>;
};


export type SubscriptionRevokeKeyKeeperProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRevokeKeyKeeperProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RevokeKeyKeeperProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RevokeKeyKeeperProposal_Filter>;
};


export type SubscriptionRoleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRolesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Role_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Role_Filter>;
};


export type SubscriptionTransferOwnershipProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransferOwnershipProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferOwnershipProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransferOwnershipProposal_Filter>;
};


export type SubscriptionTransferTokenOwnershipProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransferTokenOwnershipProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferTokenOwnershipProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransferTokenOwnershipProposal_Filter>;
};


export type SubscriptionUpgradeableProposalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUpgradeableProposalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UpgradeableProposal_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UpgradeableProposal_Filter>;
};

export type TransferOwnershipProposal = {
  __typename?: 'TransferOwnershipProposal';
  administratorApproved: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  keyKeeperApproved: Scalars['Boolean']['output'];
  newOwner: Scalars['Bytes']['output'];
  status: Scalars['Int']['output'];
};

export type TransferOwnershipProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  administratorApproved?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  administratorApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  administratorApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  and?: InputMaybe<Array<InputMaybe<TransferOwnershipProposal_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  keyKeeperApproved?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeperApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  newOwner?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newOwner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_not?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  or?: InputMaybe<Array<InputMaybe<TransferOwnershipProposal_Filter>>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum TransferOwnershipProposal_OrderBy {
  AdministratorApproved = 'administratorApproved',
  Id = 'id',
  KeyKeeperApproved = 'keyKeeperApproved',
  NewOwner = 'newOwner',
  Status = 'status'
}

export type TransferTokenOwnershipProposal = {
  __typename?: 'TransferTokenOwnershipProposal';
  id: Scalars['ID']['output'];
  index: Scalars['BigInt']['output'];
  newOwner: Scalars['Bytes']['output'];
  owner: Scalars['Bytes']['output'];
  status: Scalars['Int']['output'];
};

export type TransferTokenOwnershipProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TransferTokenOwnershipProposal_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  index?: InputMaybe<Scalars['BigInt']['input']>;
  index_gt?: InputMaybe<Scalars['BigInt']['input']>;
  index_gte?: InputMaybe<Scalars['BigInt']['input']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index_lt?: InputMaybe<Scalars['BigInt']['input']>;
  index_lte?: InputMaybe<Scalars['BigInt']['input']>;
  index_not?: InputMaybe<Scalars['BigInt']['input']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newOwner?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newOwner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_not?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newOwner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  or?: InputMaybe<Array<InputMaybe<TransferTokenOwnershipProposal_Filter>>>;
  owner?: InputMaybe<Scalars['Bytes']['input']>;
  owner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  owner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  owner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum TransferTokenOwnershipProposal_OrderBy {
  Id = 'id',
  Index = 'index',
  NewOwner = 'newOwner',
  Owner = 'owner',
  Status = 'status'
}

export type UpgradeableProposal = {
  __typename?: 'UpgradeableProposal';
  id: Scalars['ID']['output'];
  keyKeeperApproved: Scalars['Boolean']['output'];
  newImplementation: Scalars['Bytes']['output'];
  ownerApproved: Scalars['Boolean']['output'];
  status: Scalars['Int']['output'];
};

export type UpgradeableProposal_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UpgradeableProposal_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  keyKeeperApproved?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  keyKeeperApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  keyKeeperApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  newImplementation?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newImplementation_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_not?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newImplementation_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  or?: InputMaybe<Array<InputMaybe<UpgradeableProposal_Filter>>>;
  ownerApproved?: InputMaybe<Scalars['Boolean']['input']>;
  ownerApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  ownerApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  ownerApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  status_gt?: InputMaybe<Scalars['Int']['input']>;
  status_gte?: InputMaybe<Scalars['Int']['input']>;
  status_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_lt?: InputMaybe<Scalars['Int']['input']>;
  status_lte?: InputMaybe<Scalars['Int']['input']>;
  status_not?: InputMaybe<Scalars['Int']['input']>;
  status_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum UpgradeableProposal_OrderBy {
  Id = 'id',
  KeyKeeperApproved = 'keyKeeperApproved',
  NewImplementation = 'newImplementation',
  OwnerApproved = 'ownerApproved',
  Status = 'status'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']['output']>;
  /** The block number */
  number: Scalars['Int']['output'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']['output']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String']['output'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']['output'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type OwnershipsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Ownership_Filter>;
}>;


export type OwnershipsQuery = { __typename?: 'Query', ownerships: Array<{ __typename?: 'Ownership', id: string, owner: any, index: any, amount: any, lockupDeadline: any, status: number }> };

export type CancelLockupProposalsQueryVariables = Exact<{
  where?: InputMaybe<CancelLockupProposal_Filter>;
}>;


export type CancelLockupProposalsQuery = { __typename?: 'Query', cancelLockupProposals: Array<{ __typename?: 'CancelLockupProposal', id: string, owner: any, index: any, administratorApproved: boolean, keyKeeperApproved: boolean, tokenOwnerApproved: boolean, status: number }> };

export type TransferTokenOwnershipProposalsQueryVariables = Exact<{
  where?: InputMaybe<TransferTokenOwnershipProposal_Filter>;
}>;


export type TransferTokenOwnershipProposalsQuery = { __typename?: 'Query', transferTokenOwnershipProposals: Array<{ __typename?: 'TransferTokenOwnershipProposal', id: string, owner: any, index: any, newOwner: any, status: number }> };


export const OwnershipsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ownerships"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"skip"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Ownership_filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"ownerships"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}},{"kind":"Argument","name":{"kind":"Name","value":"skip"},"value":{"kind":"Variable","name":{"kind":"Name","value":"skip"}}},{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"owner"}},{"kind":"Field","name":{"kind":"Name","value":"index"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"lockupDeadline"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<OwnershipsQuery, OwnershipsQueryVariables>;
export const CancelLockupProposalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"cancelLockupProposals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelLockupProposal_filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelLockupProposals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"owner"}},{"kind":"Field","name":{"kind":"Name","value":"index"}},{"kind":"Field","name":{"kind":"Name","value":"administratorApproved"}},{"kind":"Field","name":{"kind":"Name","value":"keyKeeperApproved"}},{"kind":"Field","name":{"kind":"Name","value":"tokenOwnerApproved"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<CancelLockupProposalsQuery, CancelLockupProposalsQueryVariables>;
export const TransferTokenOwnershipProposalsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"transferTokenOwnershipProposals"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"TransferTokenOwnershipProposal_filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"transferTokenOwnershipProposals"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"owner"}},{"kind":"Field","name":{"kind":"Name","value":"index"}},{"kind":"Field","name":{"kind":"Name","value":"newOwner"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<TransferTokenOwnershipProposalsQuery, TransferTokenOwnershipProposalsQueryVariables>;