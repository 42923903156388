import Unconnected from "./Unconnected";

import useIsInTheWrongNetwork from "../../hooks/useIsInTheWrongNetwork";
import WrongNetwork from "./WrongNetwork";
import DashboardLayout from "./DashboardLayout";
import { PropsWithChildren } from "react";
import { useStatus } from "@gusdk/gu-wallet-connector";

export interface ContentLayoutProps {
  mobileOpen: boolean;
  onDrawerClose: () => void;
}
export default function ContentLayout({
  onDrawerClose,
  mobileOpen,
  children,
}: PropsWithChildren<ContentLayoutProps>) {
  const status = useStatus();

  const isInTheWrongNetwork = useIsInTheWrongNetwork();
  const isConnected = status === "connected";

  if (!isConnected) {
    return <Unconnected />;
  }

  if (isInTheWrongNetwork) {
    return <WrongNetwork />;
  }

  return (
    <DashboardLayout mobileOpen={mobileOpen} onDrawerClose={onDrawerClose}>
      {children}
    </DashboardLayout>
  );
}
