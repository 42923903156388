import { Button, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import AddModal from "../modals/AddModal";
import { Ownership } from "../../../../apis/__generated__/graphql";
import theme from "../../../../theme";

export interface AddButtonProps {
  ownerships: Ownership[];
  refetch: () => void;
}

export default function AddButton({ ownerships, refetch }: AddButtonProps) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={handleOpen}
        size={isSmallScreen ? "small" : "medium"}
      >
        Proposal
      </Button>
      <AddModal
        open={open}
        onClose={handleClose}
        ownerships={ownerships}
        refetch={refetch}
      />
    </>
  );
}
