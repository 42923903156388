import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddIcon from "@mui/icons-material/Add";
import { useAccount } from "@gusdk/gu-wallet-connector";
import { AlertType, useAlert } from "../providers/alerts/AlertProvider";
import useMetadata from "../hooks/useMetadata";
import { useWatchAsset } from "../hooks/useWatchAsset";
import { truncateAddress } from "../libraries/utils/truncate";
import { formatEther } from "ethers";
import { env } from "../libraries/utils/env";
import useTokenBalance from "../hooks/useTokenBalance";

export default function HomePage() {
  const alert = useAlert();
  const { symbol } = useMetadata();
  const { account } = useAccount();

  const { data: tokenBalance, loading } = useTokenBalance(account);

  const { watchAsset } = useWatchAsset();

  const addAssetToWallet = async () => {
    try {
      await watchAsset(
        env.REACT_APP_CONTRACT_ADDRESS?.toLowerCase() as string,
        symbol
      );
    } catch (error: any) {
      if (/user rejected action/.test(error))
        alert.showAlert(AlertType.ERROR, "user rejected action.");
      else alert.showAlert(AlertType.ERROR, "Error.");
    }
  };

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Dashboard
        </Typography>
      </Stack>

      <Box sx={{ flexGrow: 1 }} width="80%" ml="auto" mr="auto">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              boxShadow={1}
              padding={2}
              borderRadius={1}
              bgcolor="primary.main"
              color="white"
            >
              <Stack direction="row-reverse" spacing={1} textAlign="end">
                <Typography>
                  {truncateAddress(account?.toString() || "")}
                </Typography>
                <AccountBalanceWalletIcon />
              </Stack>
              <Stack direction="row">
                <Box>
                  <Typography variant="subtitle1">Vesting Balance</Typography>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Typography>{`${formatEther(
                      tokenBalance
                    )} ${symbol}`}</Typography>
                  )}
                </Box>
                <Button
                  variant="outlined"
                  sx={{ color: "white", ml: "auto", mt: "auto", padding: 0 }}
                  startIcon={<AddIcon />}
                  onClick={addAssetToWallet}
                >
                  Add To Wallet
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
