import Box from "@mui/material/Box";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useAccount } from "@gusdk/gu-wallet-connector";
import { GET_CANCEL_LOCKUP_PROPOSALS } from "../libraries/queries/proposals";
import { CancelLockupProposal } from "../apis/__generated__/graphql";
import CancelLockupTable from "../components/proposals/cancel-lockup/tables/CancelLockupProposalTable";
import AddButton from "../components/proposals/cancel-lockup/buttons/AddButton";
import { GET_OWNERSHIPS } from "../libraries/queries/ownerships";
import { ProposalStatus } from "../libraries/types/proposal";
import { OwnershipTypes } from "../libraries/utils/constants";
import theme from "../theme";
import ProposalTabs from "../components/shared/ProposalTabs";
import ProceedTable from "../components/proposals/cancel-lockup/tables/ProceedTable";

export default function CancelLockupPage() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { account } = useAccount();
  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_CANCEL_LOCKUP_PROPOSALS, {
    variables: {
      where: {
        owner: account.toLowerCase(),
        status: ProposalStatus.Pending,
      },
    },
  });

  const { data: vestingData } = useQuery(GET_OWNERSHIPS, {
    variables: {
      where: {
        owner: account,
        status_in: [
          OwnershipTypes.VESTING_LOCKUP,
          OwnershipTypes.OWNERSHIP_LOCKUP,
        ],
      },
    },
  });

  const ownerships = vestingData?.ownerships || [];

  const proposals = (proposalData?.cancelLockupProposals ||
    []) as CancelLockupProposal[];

  const { data: proceedData, loading: proceedLoading } = useQuery(
    GET_CANCEL_LOCKUP_PROPOSALS,
    {
      variables: {
        where: {
          owner: account.toLowerCase(),
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );

  const proceedProposals = (proceedData?.cancelLockupProposals ||
    []) as CancelLockupProposal[];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant={isSmallScreen ? "h6" : "h5"} sx={{ flexGrow: 1 }}>
          Cancel Lockup Proposal
        </Typography>
        <AddButton refetch={refetch} ownerships={ownerships} />
      </Stack>
      <ProposalTabs
        processingTab={
          <CancelLockupTable
            proposals={proposals}
            loading={loading}
            refetch={refetch}
          />
        }
        proceedTab={
          <ProceedTable loading={proceedLoading} proposals={proceedProposals} />
        }
      />
    </Box>
  );
}
