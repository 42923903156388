import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Link, useLocation } from "react-router-dom";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import ListIcon from "@mui/icons-material/List";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { PropsWithChildren, useState } from "react";
import { Collapse, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import theme from "../../theme";

const DRAWER_WIDTH = 240;
const DRAWER_MEDIUM_WIDTH = 200;

const LINKS = [
  { text: "Dashboard", href: "/", icon: DashboardIcon },
  { text: "Ownerships", href: "/ownerships", icon: ListIcon },
];

const PROPOSAL_LINKS = [
  { text: "Cancel Lockup", href: "/proposals/cancel-lockup" },
  {
    text: "Transfer Token Ownership",
    href: "/proposals/transfer-token-ownership",
  },
];

export interface DashboardLayoutProps {
  mobileOpen: boolean;
  onDrawerClose: () => void;
}
export default function DashboardLayout({
  mobileOpen,
  children,
  onDrawerClose,
}: PropsWithChildren<DashboardLayoutProps>) {
  const location = useLocation();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
            top: ["48px", "56px", "64px"],
            height: "auto",
            bottom: 0,
          },
          display: { xs: "block", md: "none" },
        }}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={onDrawerClose}
      >
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton
                component={Link}
                to={href}
                selected={href == location.pathname}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText primary="Proposals" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {PROPOSAL_LINKS.map((proposal) => (
                <ListItemButton
                  key={proposal.href}
                  component={Link}
                  to={proposal.href}
                  sx={{ pl: 4 }}
                  selected={proposal.href == location.pathname}
                >
                  <ListItemText primary={proposal.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider sx={{ mt: "auto" }} />
      </Drawer>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isLargeScreen ? DRAWER_MEDIUM_WIDTH : DRAWER_WIDTH,
            boxSizing: "border-box",
            top: ["48px", "56px", "64px"],
            height: "auto",
            bottom: 0,
          },
          display: { xs: "none", md: "block" },
        }}
        variant="permanent"
        anchor="left"
      >
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton
                component={Link}
                to={href}
                selected={href == location.pathname}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText primary="Proposals" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {PROPOSAL_LINKS.map((proposal) => (
                <ListItemButton
                  key={proposal.href}
                  component={Link}
                  to={proposal.href}
                  sx={{ pl: 4 }}
                  selected={proposal.href == location.pathname}
                >
                  <ListItemText primary={proposal.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider sx={{ mt: "auto" }} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          ml: `${
            isMediumScreen
              ? 0
              : isLargeScreen
              ? DRAWER_MEDIUM_WIDTH
              : DRAWER_WIDTH
          }px`,
          mt: ["48px", "56px", "64px"],
          pt: 3,
          pl: [0, 0, 3],
          pr: [0, 0, 3],
        }}
      >
        {children}
      </Box>
    </>
  );
}
