import { useClient } from '@gusdk/gu-wallet-connector';

export function useWatchAsset() {
  const client = useClient();
  const connector = client.connector;

  const watchAsset = (address: string, symbol: string) => {
    return connector?.watchAsset?.({
      address,
      symbol,
    });
  };

  return { watchAsset };
}
