import packageJson from "../../package.json";

export default function AboutPage() {
  return (
    <div>
      <h1>About</h1>
      <h2>Version: {packageJson.version}</h2>
    </div>
  );
}
