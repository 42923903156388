import { gql } from "../../apis/__generated__";

export const GET_CANCEL_LOCKUP_PROPOSALS = gql(/* GraphQL */ `
  query cancelLockupProposals($where: CancelLockupProposal_filter) {
    cancelLockupProposals(where: $where) {
      id
      owner
      index
      administratorApproved
      keyKeeperApproved
      tokenOwnerApproved
      status
    }
  }
`);

export const GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS = gql(/* GraphQL */ `
  query transferTokenOwnershipProposals(
    $where: TransferTokenOwnershipProposal_filter
  ) {
    transferTokenOwnershipProposals(where: $where) {
      id
      owner
      index
      newOwner
      status
    }
  }
`);
