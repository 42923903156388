import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useState } from "react";
import { Chip, Stack, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { WalletConnectorDialog, useStatus } from "@gusdk/gu-wallet-connector";
import DropdownMenu from "./DropdownMenu";
import { useChain } from "../../../hooks/useChain";
import useIsInTheWrongNetwork from "../../../hooks/useIsInTheWrongNetwork";
import theme from "../../../theme";

export interface AppBarProps {
  onDrawerToggle: () => void;
}

export default function AppBar({ onDrawerToggle }: AppBarProps) {
  const status = useStatus();
  const chain = useChain();
  const [open, setOpen] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isInTheWrongNetwork = useIsInTheWrongNetwork();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isConnected = status === "connected";

  return (
    <MuiAppBar position="fixed" sx={{ zIndex: 1300 }}>
      <Toolbar sx={{ backgroundColor: "background.paper" }}>
        {isConnected && !isInTheWrongNetwork && (
          <IconButton
            edge="start"
            sx={{ mr: [0, 2], display: { md: "none" } }}
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="black"
          sx={{ flexGrow: 1 }}
          fontSize={isSmallScreen ? 14 : 20}
        >
          JOC Token Manager
        </Typography>
        {isConnected ? (
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            {!isSmallScreen && <Chip label={chain?.name} />}
            <DropdownMenu />
          </Stack>
        ) : (
          <IconButton
            size="large"
            edge="start"
            sx={{ mr: 2 }}
            onClick={handleClickOpen}
          >
            <AccountBalanceWalletIcon />
          </IconButton>
        )}
        <WalletConnectorDialog open={open} onClose={handleClose} />
      </Toolbar>
    </MuiAppBar>
  );
}
