import { useEffect, useState } from "react";
import { useContract } from "./useContract";

export default function useTokenBalance(address: string) {
  const contract = useContract();
  const [tokenBalance, setTokenBalance] = useState(BigInt(0));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const balance = await contract.balanceOf(address);

      setTokenBalance(balance);
      setLoading(false);
    })();
  }, [address, contract]);

  return { data: tokenBalance, loading };
}
