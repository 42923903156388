import Box from "@mui/material/Box";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useAccount } from "@gusdk/gu-wallet-connector";
import TransferTokenOwnershipProposalTable from "../components/proposals/transfer-token-ownership/tables/TransferTokenOwnershipProposalTable";
import AddButton from "../components/proposals/transfer-token-ownership/buttons/AddButton";
import { GET_OWNERSHIPS } from "../libraries/queries/ownerships";
import { ProposalStatus } from "../libraries/types/proposal";
import { GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS } from "../libraries/queries/proposals";
import { TransferTokenOwnershipProposal } from "../apis/__generated__/graphql";
import theme from "../theme";
import ProposalTabs from "../components/shared/ProposalTabs";
import { OwnershipTypes } from "../libraries/utils/constants";
import ProceedTransferTokenOwnershipProposalTable from "../components/proposals/transfer-token-ownership/tables/ProceedTransferTokenOwnershipProposalTable";

export default function TransferVestingProposalPage() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { account } = useAccount();
  const {
    data: proposalData,
    refetch: refetchProposal,
    loading,
  } = useQuery(GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS, {
    variables: {
      where: {
        owner: account?.toLowerCase(),
        status: ProposalStatus.Pending,
      },
    },
  });

  const { data: vestingData } = useQuery(GET_OWNERSHIPS, {
    variables: {
      where: {
        owner: account.toLowerCase(),
        status_in: [
          OwnershipTypes.VESTING_LOCKUP,
          OwnershipTypes.OWNERSHIP_LOCKUP,
          OwnershipTypes.PROVISIONAL,
        ],
      },
    },
  });

  const proposals = (proposalData?.transferTokenOwnershipProposals ||
    []) as TransferTokenOwnershipProposal[];

  const {
    data: proceedData,
    loading: proceedLoading,
    refetch: refetchProceed,
  } = useQuery(GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS, {
    variables: {
      where: {
        owner: account?.toLowerCase(),
        status_not: ProposalStatus.Pending,
      },
    },
  });
  const proceedProposals = (proceedData?.transferTokenOwnershipProposals ||
    []) as TransferTokenOwnershipProposal[];

  const refetch = () => {
    refetchProposal();
    refetchProceed();
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant={isSmallScreen ? "h6" : "h5"} sx={{ flexGrow: 1 }}>
          Transfer Token Ownership Proposal
        </Typography>
        <AddButton
          refetch={refetch}
          ownerships={vestingData?.ownerships || []}
        />
      </Stack>
      <ProposalTabs
        processingTab={
          <TransferTokenOwnershipProposalTable
            proposals={proposals}
            loading={loading}
            refetch={refetch}
          />
        }
        proceedTab={
          <ProceedTransferTokenOwnershipProposalTable
            proposals={proceedProposals}
            loading={proceedLoading}
          />
        }
      />
    </Box>
  );
}
