import * as React from "react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Providers from "../providers/providers";
import AppBar from "../components/Layout/AppBar/AppBar";
import { Outlet } from "react-router-dom";
import ContentLayout from "../components/Layout/ContentLayout";

export default function RootPage() {
  dayjs.extend(duration);

  return (
    <Providers>
      <App />
    </Providers>
  );
}

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <>
      <AppBar onDrawerToggle={handleDrawerToggle} />
      <ContentLayout mobileOpen={mobileOpen} onDrawerClose={handleDrawerClose}>
        <Outlet />
      </ContentLayout>
    </>
  );
}
