import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootPage from "./routes/root";
import ErrorPage from "./error-page";
import AboutPage from "./routes/about";
import OwnershipPage from "./routes/ownership";
import HomePage from "./routes/home";
import CancelLockupProposalPage from "./routes/cancel-lockup";
import TransferTokenOwnershipProposalPage from "./routes/transfer-token-ownership";

import { generateLicense, LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: "1",
    licensingModel: "subscription",
    scope: "premium",
  })
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <HomePage /> },
      {
        path: "/ownerships",
        element: <OwnershipPage />,
      },
      {
        path: "/proposals/cancel-lockup",
        element: <CancelLockupProposalPage />,
      },
      {
        path: "/proposals/transfer-token-ownership",
        element: <TransferTokenOwnershipProposalPage />,
      },
    ],
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
