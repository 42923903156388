import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCallback, useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { parseEther, formatEther } from "ethers";
import { Ownership } from "../../../../apis/__generated__/graphql";
import { useContract } from "../../../../hooks/useContract";
import useMetadata from "../../../../hooks/useMetadata";
import {
  AlertType,
  useAlert,
} from "../../../../providers/alerts/AlertProvider";
import { errorDecoder } from "../../../../libraries/utils/error-decoder";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

export interface WithdrawModalProps {
  open: boolean;
  onClose: () => void;
  row: Ownership;
  refetch: () => void;
}

export interface WithdrawValues {
  amount: string;
}

export default function WithdrawModal({
  open,
  onClose,
  row,
  refetch,
}: WithdrawModalProps) {
  const contract = useContract();
  const { symbol } = useMetadata();
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);

  const onSubmit = async (data: WithdrawValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract["withdrawToken(uint256,uint256)"](
        row.index,
        parseEther(data.amount)
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2">
              Withdraw
            </Typography>
            <Typography variant="body1" mb={1}>
              Fill amount you willing to withdraw
            </Typography>

            <FormContainer onSuccess={onSubmit}>
              <Stack direction="column" spacing={2}>
                <Stack direction="column" spacing={1}>
                  <TextFieldElement
                    fullWidth
                    name="amount"
                    label="Amount"
                    validation={{
                      required: true,
                      validate: (value) => {
                        try {
                          if (parseEther(value) === BigInt(0))
                            return "Amount must greater than zero";
                          else if (parseEther(value) > BigInt(row.amount))
                            return "Exceed available amount";
                          return true;
                        } catch (error) {
                          return "Invalid number";
                        }
                      },
                    }}
                  />
                  <Typography variant="caption">
                    Available {formatEther(row.amount)} {symbol}
                  </Typography>
                </Stack>

                <Button type="submit" variant="contained">
                  Withdraw
                </Button>
              </Stack>
            </FormContainer>
          </Box>
        )}

        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
