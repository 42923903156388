import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";

import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DateTimePickerElement, FormContainer } from "react-hook-form-mui";
import { Ownership } from "../../../../apis/__generated__/graphql";
import { useContract } from "../../../../hooks/useContract";
import {
  AlertType,
  useAlert,
} from "../../../../providers/alerts/AlertProvider";
import { errorDecoder } from "../../../../libraries/utils/error-decoder";
import useMaximumExtendPeriod from "../../../../hooks/useMaximumExtendPeriod";
export interface ExtendModalProps {
  row: Ownership;
  open: boolean;
  onClose: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface ExtendDaysValues {
  deadline: Dayjs;
}

export default function ExtendModal({ open, onClose, row }: ExtendModalProps) {
  const contract = useContract();
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const { data: maximumExtendPeriod } = useMaximumExtendPeriod();

  const onSubmit = async (data: ExtendDaysValues) => {
    try {
      setStep(Steps.Waiting);
      const extension = dayjs
        .duration(
          dayjs(data.deadline).unix() - dayjs.unix(row.lockupDeadline).unix(),
          "seconds"
        )
        .asSeconds();
      const tx = await contract.extendLockupPeriod(row.index, extension);
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  const defaultValues: ExtendDaysValues = {
    deadline: dayjs.unix(row.lockupDeadline),
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2">
              Extend Lockup Period
            </Typography>
            <Typography variant="body1" mb={1}>
              Lockup can be extended for up to one year
            </Typography>

            <FormContainer onSuccess={onSubmit} defaultValues={defaultValues}>
              <Stack direction="column" spacing={2}>
                <Stack direction="column" spacing={1}>
                  <DateTimePickerElement
                    name="deadline"
                    label="Deadline"
                    disablePast
                    validation={{
                      required: true,
                    }}
                    minDateTime={dayjs.unix(row?.lockupDeadline)}
                    maxDateTime={dayjs().add(maximumExtendPeriod, "seconds")}
                    ampm={false}
                  />
                </Stack>

                <Button type="submit" variant="contained">
                  Extend
                </Button>
              </Stack>
            </FormContainer>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
